#dropdown-basic::after {
    display: none;
}

.default-green {
    background-color: rgb(42, 150, 13) !important;
}

.container-sb {
    display: grid !important;
    grid-auto-rows: 2fr !important;
}

.data_market2 abbr {
    width: 100%;
}

.area-data-market .title {
    padding: 0 !important;
}

.area-data-market abbr {
    text-align: start;
}

.default-small-font-size {
    font-size: 12px;
}

.bg-sb {
    background-color: #eceff4;
}

.bg-sb-dark {
    background-color: #191c20;
}

.header-table {
    height: 24px;
}

.area-profit {
    width: calc((85/1200) * 100vw) !important;
}

.area-bet-home {
    width: calc((117/1200) * 100vw) !important;
}

.area-date-time {
    width: calc((77/1200) * 100vw) !important;
}

.area-event {
    width: calc((358/1200) * 100vw) !important;
}

.area-data-market {
    width: calc((211/1200) * 100vw) !important;
}

.area-chance {
    width: calc((55/1200) * 100vw) !important;
}


.area-profit-grid-desktop {
    grid-area: area-profit-desktop;
}

.info-surebet-grid {
    margin: auto;
}

.area-hide-surebet {
    display: flex;
    justify-content: end;


    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 4;
}

.profit-mobile {
    padding-top: 3px;
    padding-bottom: 3px;
    display: none !important;
}

.area-sb {
    width: 100%;
    height: 100%;
    display: grid;
    padding-bottom: 10px;
    grid-template-columns: calc((85/1200) * 100vw) calc((818/1200) * 100vw) auto;
}

.open-all-odds {

    height: 100% !important;
    max-height: 84px !important;
}

.logo-star,
.empty-space-logo,
.logo-live {
    height: 15px;
    width: auto;
}

#filter-desktop .mt-3 {
    margin-top: 0 !important;
}

.fs-small {
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.d-new-0 {
    display: none;
    font-size: 15px !important;
}

.btn-pop-up-redirect {
    margin-left: 48px;
}

.btn-pop-up-redirect,
.button-extension-desktop,
.button-bonus-desktop,
.button-extension-mobile,
.button-bonus-mobile {
    background: #29910d;
    padding: 4px 8px;
    box-shadow: 0px 3.5px 2px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    list-style: none;
    text-decoration: none !important;
}

.btn-pop-up-redirect:hover,
.button-extension-desktop:hover,
.button-bonus-desktop:hover,
.button-extension-mobile:hover,
.button-bonus-mobile:hover {
    background: #25a602;
}

.icon-video-pop-up {
    margin-left: 10px;
    width: 28px;

}

.layout-mobile-cell-phone {
    display: none;
}

[data-bs-theme=dark-theme] {
    .mode-h4 {
        color: #FFF;
    }
}

.wanrning-danger-bet-home {
    display: none;
}

.white-space-nowrap {
    white-space: nowrap;
}


.header-extension-desktop {
    height: 90px;
    background-color: #FFF;
    overflow: hidden;
}

.strip-extension-desktop {
    height: 25px;
    background-color: #006000;

}

.conatainer-extension-desktop {
    height: 65px;
}

.container-logo-desktop {
    position: relative;
    background-color: #006000;
    width: 180px;
    height: 68px;
    top: -1px;
    clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
}

.container-img-logo-desktop {
    margin-left: 20px;
}

.info-surebet-desktop {
    width: calc(100% - 180px);
}

.fs-title-extention-desktop {
    font-size: 17px;
}

.fs-sub-title-extention-desktop {
    font-size: 14px;
}

.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100%;
}


.fs-sub-title-extention-desktop abbr {
    font-size: 14px !important;
}

.fs-sub-title-extention-desktop abbr * {
    color: #000 !important;
}

.fs-sub-title-extention-desktop p {
    padding: 0;
    margin: 0;
}

.header-extension-mobile {


    height: calc((90/360) * 100vw) !important;
    width: 100vw;
    background-color: #006000;
}


.area-logo-button-extension-mobile,
.area-logo-button-bonus-mobile {
    height: calc((30/360) * 100vw) !important;
}

.img-logo-extension-mobile {
    height: calc((20/360) * 100vw) !important;
}

.fs-normal-extension-mobile {
    font-size: calc((12/360) * 100vw) !important;
}

.fs-title-extension-mobile {
    font-size: calc((14/360) * 100vw) !important;
}

.custom-line-height-extension-mobile {
    line-height: calc((15/360) * 100vw) !important;
}


.w-60 {
    width: 60%;
}

.w-40 {
    width: 40%;
}

.custom-padding-start-extension-mobile {
    padding-left: calc((5/360) * 100vw) !important;
}

.fs-normal-extension-mobile abbr {
    font-size: calc((12/360) * 100vw) !important;
}

.fs-normal-extension-mobile abbr * {
    color: #FFF !important;
}

.fs-normal-extension-mobile abbr .legenda-2 {
    font-size: calc((10/360) * 100vw) !important;

}

.arbitragem-extension-mobile {
    display: none;
}

.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.icon-extension {
    width: 30px;
    height: 30px;
}

.button-extension-mobile,
.button-bonus-mobile {
    display: none !important;
}

.icon-bonus {
    height: 20px;
}

.style-menu-home {
    flex-wrap: wrap;
    justify-content: center;
}

.cus:checked {
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
}

.style-icon-alert-filter {
    height: 20px;
}

.style-height-fit-content {
    height: fit-content;
}

.style-icon-stop-pause-surebet-live {
    height: 45px;
    width: 45px;
}

.style-fs-btn-delete-restore {
    font-size: 14px !important;
    text-align: center;
}


.style-container-btn-pause-stop-desktop {
    right: 1px;
    top: 20%;
}

.style-btn-play-stop {
    height: 100%;
    max-height: 84px !important;
}

.style-dropdown-trash,
.style-dropdown-trash button {
    width: 100% !important;
    max-width: 100% !important;
}

.style-filter-surebet-desktop {
    display: grid;
    grid-template-rows: repeat(20, auto);
    grid-auto-flow: column;
}

.style-container-undisponible-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;


}

.style-fs-1 {
    font-size: 2.5rem !important;
}

.style-fs-4 {
    font-size: 1.5rem !important;
}

.style-bg-dark-container-undisponible-plan {
    background: #212121;
}


/*1*/
.style-modal .btn-close {
    background-color: #fff;
    color: #fff;
}

.style-modal-market .modal-content{
    width: min-content;
}

.style-modal .btn-close {
    background-color: #fff;
    color: #fff;
}

/*3*/
.style-bg-dark{
    background-color: #0d0d0d;
}

/*4*/
.style-border-color{
    border-color: #24282b !important;
}

/*5*/
.filter-text-gray{
    color: #ced2d6 !important;
}

/*6*/
.style-text-gray{
    color: #ced2d6 !important;
}

.style-container-mark-as-read{
    display: flex;
    justify-content: start;
}

@media (max-width: 1450px) {
    .area-sb {
        padding-bottom: 0;
    }


    .d-new-0 {
        display: block;
    }

    .d-new-1 {
        display: none;
    }

    .text-profit {
        display: flex;
        flex-direction: column;

        line-height: 1;

    }

    .style-icon-stop-pause-surebet-live {
        height: calc((45/1400) * 100vw) !important;
        width: calc((45/1400) * 100vw) !important;
    }

    .style-container-btn-pause-stop-desktop {

        top: 25%;
    }
}

@media (max-width: 1200px) {
    .white-space-div {
        display: none !important;
    }

    .area-profit {
        width: calc((70/892) * 100vw) !important;
    }

    .area-bet-home {
        width: calc((110/892) * 100vw) !important;
    }

    .area-date-time {
        width: calc((75/892) * 100vw) !important;
    }

    .area-event {
        width: calc((352/892) * 100vw) !important;
    }

    .area-data-market {
        width: calc((180/892) * 100vw) !important;
    }

    .area-chance {
        width: calc((54/892) * 100vw) !important;
    }

    .area-sb {
        display: grid;
        grid-template-columns: calc((70/892) * 100vw) calc((771/892) * 100vw) auto;
    }

    .wanrning-danger-bet-home {
        display: block;
    }

    .style-icon-stop-pause-surebet-live {
        height: calc((45/1200) * 100vw) !important;
        width: calc((45/1200) * 100vw) !important;
    }

    .style-fs-1 {
        font-size: calc(1.375rem + 1.5vw) !important;
    }

    .style-fs-4 {
        font-size: calc(1.275rem + .3vw) !important;
    }

}

@media (max-width: 1199px) {
    .style-bonus-menu-home {
        margin-top: 15px;
    }

    .style-container-btn-login {
        justify-content: center !important;
        flex-direction: inherit !important;
    }
}

@media (max-width: 1150px) {
    .header-extension-desktop {
        height: calc((90/1150) * 100vw) !important;
    }

    .strip-extension-desktop {
        height: calc((25/1150) * 100vw) !important;
    }

    .conatainer-extension-desktop {
        height: calc((65/1150) * 100vw) !important;
    }

    .container-logo-desktop {
        width: calc((180/1150) * 100vw) !important;
        height: calc((68/1150) * 100vw) !important;
        top: calc((-1/1150) * 100vw) !important;


    }

    .container-img-logo-desktop {
        margin-left: calc((20/1150) * 100vw) !important;
    }

    .info-surebet-desktop {
        width: calc((970/1150) * 100vw) !important;
    }

    .fs-title-extention-desktop {
        font-size: calc((17/1150) * 100vw) !important;
    }

    .fs-sub-title-extention-desktop,
    .fs-sub-title-extention-desktop abbr {
        font-size: calc((14/1150) * 100vw) !important;
    }


    .fs-sub-title-extention-desktop abbr .legenda-2 {
        font-size: calc((12/1150) * 100vw) !important;
    }

}

@media (max-width: 960px) {
    .text-profit div {
        font-size: calc((20/960) * 100vw) !important;
    }

}

@media (max-width: 892px) {
    .profit-mobile {
        display: flex !important;
    }

    .area-profit-desktop {
        display: none !important;
    }

    .open-all-odds {
        width: 20px !important;
        height: 100% !important;
        max-height: 80% !important;
        display: flex;
        justify-content: center;
    }

    .container-sb {
        display: grid !important;
        grid-auto-rows: 1fr !important;
    }

    .profitTrashCalculatorMobile {
        display: none !important;
    }

    .area-hide-surebet {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .btn-pop-up-redirect {
        display: none !important;
        padding: calc((4/891) * 100vw) calc((8/891) * 100vw) !important;
        border-radius: calc((5/891) * 100vw) !important;
        margin-left: calc((48/891) * 100vw) !important;
    }

    .container-button-extension,
    .container-button-bonus {
        font-size: 12px;
    }

    .container-button-extension .icon-extension,
    .container-button-bonus .icon-extension {
        width: 22.5px;
        height: 22.5px;
    }



    .icon-video-pop-up {
        margin-left: calc((10/891) * 100vw) !important;
        width: calc((28/891) * 100vw) !important;
    }


    .header-table {
        height: calc((24/891) * 100vw) !important;
    }

    .area-profit {
        width: calc((95/891) * (100vw - 20px)) !important;
    }

    .area-bet-home {
        width: calc((95/891) * (100vw - 20px)) !important;
        padding-left: calc((8/891) * 100vw) !important;
    }

    .area-date-time {
        width: calc((75/891) * (100vw - 20px)) !important;
    }

    .area-event {
        width: calc((406/891) * (100vw - 20px)) !important;
    }

    .area-data-market {
        width: calc((203/891) * (100vw - 20px)) !important;
    }

    .area-chance {
        width: calc((54/891) * (100vw - 20px)) !important;
    }



    .default-font-size {
        font-size: calc((16/891) * 100vw) !important;
    }

    .default-small-font-size {
        font-size: calc((12/891) * 100vw) !important;
    }

    .padding-time-badge {
        padding-top: calc((4.2/891) * 100vw) !important;
        padding-bottom: calc((4.2/891) * 100vw) !important;
        padding-left: calc((7.8/891) * 100vw) !important;
        padding-right: calc((7.8/891) * 100vw) !important;
    }

    .area-sb {
        display: grid;
        grid-template-columns: calc((833/891) * (100vw - 20px)) auto;
    }

    .logo-star,
    .empty-space-logo,
    .logo-live {
        height: calc((20/891) * 100vw) !important;
    }

    .legenda-2 {
        font-size: calc((12/891) * 100vw) !important;
    }

    .area-data-market abbr {
        font-size: calc((16/891) * 100vw) !important;
    }

    .fs-small {
        font-size: calc((12/891) * 100vw) !important;
    }

    .icon-calculator {
        width: 21px;
        height: 21px;
    }

    .icon-trash {
        width: 23px;
        height: 23px;
    }

    .icon-back-trash {
        width: 11px;
        height: 11px;
    }

    .font-size-calculator-trash,
    .font-size-filter-out-desktop-mode {
        font-size: 12px;
    }

    .btn-p-mobile {
        padding: calc((6/891) * 100vw) calc((12/891) * 100vw) !important;
        border-radius: calc((6/891) * 100vw) !important;
    }

    .style-icon-stop-pause-surebet-live {
        height: calc((30/892) * 100vw) !important;
        width: calc((30/892) * 100vw) !important;
    }

    .style-container-btn-pause-stop-desktop {
        display: none !important;
    }

}


@media (max-width: 550px) {
    .style-text-calculator-mobile {
        display: none !important;
    }
}

@media (max-width: 500px) {
    .layout-mobile-desktop-and-tablet {
        display: none;
    }


    .layout-mobile-cell-phone {
        display: block;
    }

    .icon-calculator {
        width: 18px;
        height: 18px;
    }

    .icon-trash {
        width: 20px;
        height: 20px;
    }

    .icon-back-trash {
        width: 8px;
        height: 8px;
    }

    .font-size-calculator-trash,
    .font-size-filter-out-desktop-mode {
        font-size: 9px;
    }

    .header-table * {
        display: none !important;

    }

    .header-table {
        height: 24px !important;
    }

    .data-market-0-surebet-mobile abbr {
        font-size: auto !important;
    }

    .arbitragem-extension-mobile {
        display: block;
    }

    .arbitragem-extension-desktop {
        display: none;
    }

    .container-button-extension p {
        font-size: 9px;
        display: none;
    }

    .btn-calculator {
        margin-right: calc((16/500) * 100vw) !important;
    }

    .container-button-extension,
    .container-button-bonus {
        margin-left: calc((16/500) * 100vw) !important;
        padding: calc((6/891) * 100vw) calc((12/891) * 100vw) !important;
        border-radius: calc((6/891) * 100vw) !important;
    }

    .container-button-extension .icon-extension,
    .container-button-bonus .icon-extension {
        width: 16.88px;
        height: 16.88px;
        margin: 0 !important;
    }

    .style-icon-stop-pause-surebet-live {
        width: 30px !important;
        height: 30px !important;
    }

    .logo-live {
        width: 15px !important;
        height: 15px !important;
    }

    .style-container-undisponible-plan {
        height: calc((500/500) * 100vw) !important;
    }

    .style-fs-1 {
        font-size: calc((30/500) * 100vw) !important;
    }

    .style-fs-4 {
        font-size: calc((22/500) * 100vw) !important;
    }
}


@media (max-width: 600px) {

    .button-extension-mobile,
    .button-bonus-mobile {
        display: flex !important;
    }

    .button-extension-desktop,
    .button-bonus-desktop {
        display: none !important;
    }

}