.filter-border-color{
    border-color: #24282b !important;
}

.filter-boxOptions{
    font-size: 1rem!important;
}

.filter-text-gray{
    color: #ced2d6 !important;
}

.filter-text-faixa-lucro {
    font-size: 0.85rem !important;
}

.filter-container {
    width: max-content;
    background: #fff;
    border-radius: 3rem;
}

.filter-hoverGray:hover,
.filter-active.filter-hoverGray {
  background-color: #24282b;
  color: #ced2d6 !important;
}

.filter-bg-dark{
    background-color: #0d0d0d;
}

.filter-container-check-market{
    display: grid;
    grid-template-rows: repeat(14, auto);
    grid-template-columns: 270px;
    grid-auto-flow: column;
}


.filter-modal .btn-close {
    background-color: #fff;
    color: #fff;
}

.filter-modal-market .modal-content{
    width: min-content;
}

.filter-label-modal{
    width: max-content;
}

.filter-modal .btn-close {
    background-color: #fff;
    color: #fff;
}

.filter-dark-mode .modal-content {
    background-color: #0d0d0d;
}


.filter-button-set-keyword-surebet{
    width: 60px;
}

.filter-button-set-keyword-surebet, .filter-button-set-keyword-surebet-mobile {
    background-color: #ff5454;
    border-color: #ff5454;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-button-set-keyword-surebet img {
    width: 40px;
    height: 40px;
}

.filter-input-keyword-surebet,
.filter-button-set-keyword-surebet {
    height: 40px;
}


.filter-inside-event-functionality {
    min-height: 300px;
}

.filter-min-content {
    width: fit-content;
    height: min-content;
    display: flex;
    position: relative;
}


.filter-container-keywords-saved {
    max-height: 300px;
    overflow-y: auto;
}

.filter-div-keywords-saved {
    display: flex;
    flex-wrap: wrap;
}


.filter-container-event-btn-close {
    background-color: #dc3545;
    height: min-content;
    width: min-content;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
}

.filter-event-btn-close {
    background-image: url("../svg/close.svg");
    background-position: center;
    height: 12px !important;
    width: 12px !important;
    border-radius: 50%;

}

.filter-container-event-btn-close:active {
    background-color: #f56f7d !important;
}

.filter-container-event-btn-close:hover {
    background-color: #af3642;
}

.filter-white-space-btn-remove-keyword{
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.filter-box-header {
    padding: 2rem 2rem;
}

.filter-box-header,
.filter-box-options {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}


.filter-svg-back-page {
    height: 40px;
    width: 40px;
}


.filter-input-keyword-surebet-mobile,
.filter-button-set-keyword-surebet-mobile {
    height: 30px;
}
.filter-button-set-keyword-surebet-mobile{
    width: 45px;
}

.filter-container-mobile{
    width: 300px;
    height: 502px;
    
    border-radius: 10px;
    overflow: hidden;
}


.filter-bg-light{
    background-color: #FFF;
}