.read-the-docs {
  color: #888;
}

.tranding-slider {
  width: 100%;
  padding: 50px 0;
}

.tranding-slider .swiper-slide {
  width: 350px;
  height: 535px;
  background-color: transparent;
  border-radius: 10px;
}

/* Customize navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  background: #FFF;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  top: 95%;
}


.swiper-button-next::after,
.swiper-button-prev::after{
  font-size: 25px;
  font-weight: bold;
  color: rgb(42, 150, 13);
}

.tranding-slider-control{
  background-color: red;
}


.w-h-100 {
  width: 100%;
  height: 100%;

}


.tranding-slider .swiper-slide {
  background-size: cover;
  /*background-color: red;*/
}

.container-slider {

  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.container-plan {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.btn-buy {
  margin-bottom: 12%;
  width: min-content;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1.29rem;
  font-weight: 900;
  border: none;
  border-radius: 15px;

  box-shadow: 0 4px 6px #0006;
  color: #fff;
  padding: 10px 20px;
  width: 70%;
  background: #0000;
}

.letter-discount {
  color: #ff0100;
  font-size: 20px;
  font-weight: bold;
}

.container-time {
  display: flex;
  justify-content: center;
  align-items: start;
}

#time-plan{
  font-size: 60px;
  font-weight: bold; 
  color: #FFF;
}

@media (max-width: 450px) {
  #time-plan{
    font-size: 40px;
  }
  .tranding-slider .swiper-slide {
    width: 250px;
    height: 382.14px;
  }

  .btn-buy {
    width: 80%;
    font-size: 15px;
    border-radius: 13px;
    padding: 8px 18px;
  }
}

@media (max-width: 400px) {
  .tranding-slider .swiper-slide {
    width: 200px;
    height: 305.71px;
  }

  .btn-buy {
    width: 80%;
    font-size: 12px;
    border-radius: 7px;
    padding: 5px 15px;
  }
}